<template lang="">
  <div>
    <BModal
      id="modal-edit-ctcm-ctce-for-1g-modal"
      :title="$t('reservation.editCtcmCtceFor1g.title')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      size="lg"
      no-close-on-backdrop
      @show="showHandle"
    >
      <IAmOverlay :loading="loading">
        <span v-if="bookingCode">
          Mã đặt chỗ: <span class="font-weight-bolder text-info font-medium-5">{{ bookingCode }}</span>
        </span>
        <b-form-group #default="{ ariaDescribedby }">
          <div class="d-flex-center justify-content-start gap-3 custom-radio">
            <b-form-radio
              v-model="typeOperation"
              :aria-describedby="ariaDescribedby"
              name="add-radio"
              value="ADD"
            >
              <span class="font-weight-bolder font-medium-2 mt-0">{{ $t('reservation.editCtcmCtceFor1g.addTitle') }}</span>
            </b-form-radio>
            <b-form-radio
              v-model="typeOperation"
              :aria-describedby="ariaDescribedby"
              name="delete-radio"
              value="DELETE"
            >
              <span class="font-weight-bolder font-medium-2 mt-0">{{ $t('reservation.editCtcmCtceFor1g.removeTitle') }}</span>
            </b-form-radio>
          </div>
        </b-form-group>

        <div class="text-warning font-italic my-50">
          <div v-if="addOperation">
            <div>(*) Lựa chọn hành khách để thêm CTCE/CTCM</div>
            <div>(**) vui lòng không nhập thông tin khống</div>
          </div>
          <div v-else>
            <div>(*) Lựa chọn dòng để xoá</div>
          </div>
          <div>(***) bạn sẽ chịu trách nhiệm bồi thường nếu thông tin sai</div>
        </div>

        <!-- ANCHOR ADD -->
        <div
          v-if="addOperation"
          class="my-1"
        >
          <div
            v-for="(pax, paxIndex) in paxData"
            :key="paxIndex"
          >
            <BFormCheckbox v-model="pax.isCheck">
              <div class="font-weight-bolder font-medium-1">
                {{ pax.lastName }} {{ pax.firstName }} {{ pax.title }} ({{ pax.paxId }})
              </div>
            </BFormCheckbox>
            <div
              v-if="pax.isCheck"
              class="d-flex flex-column gap-2"
            >
              <div class="d-flex-center px-2">
                <BInputGroup class="border rounded">
                  <BInputGroupPrepend
                    :class="isMobileView ? 'flex-wrap' : ''"
                    class="d-flex-center"
                    style="width: 100px; background-color: #EFEFEF "
                  >
                    <BFormCheckbox v-model="pax.isEmailCheck">
                      <div class="font-weight-bolder font-medium-1 ml-50">
                        CTCE
                      </div>
                    </BFormCheckbox>
                  </BInputGroupPrepend>
                  <b-form-input
                    :id="`ctce-${pax.paxId}`"
                    v-model.trim="pax.email"
                    :class="isMobileView ? 'border-secondary' : ''"
                    :disabled="!pax.isEmailCheck"
                    style="min-width: 145px"
                    :placeholder="`${$t('reservation.editCtcmCtceFor1g.emailPlaceholder')}`"
                  />
                </BInputGroup>
              </div>
              <div class="d-flex-center px-2">
                <BInputGroup class="border rounded">
                  <BInputGroupPrepend
                    :class="isMobileView ? 'flex-wrap' : ''"
                    class="d-flex-center"
                    style="width: 100px; background-color: #EFEFEF "
                  >
                    <BFormCheckbox v-model="pax.isPhoneCheck">
                      <div class="font-weight-bolder font-medium-1 ml-50">
                        CTCM
                      </div>
                    </BFormCheckbox>
                  </BInputGroupPrepend>
                  <b-form-input
                    :id="`ctce-${pax.paxId}`"
                    v-model.trim="pax.phone"
                    :class="isMobileView ? 'border-secondary' : ''"
                    :disabled="!pax.isPhoneCheck"
                    style="min-width: 145px"
                    :placeholder="`${$t('reservation.editCtcmCtceFor1g.phonePlaceholder')}`"
                  />
                </BInputGroup>
              </div>
            </div>
          </div>
        </div>
        <!-- ANCHOR DELETE -->
        <div
          v-else
          class="my-1"
        >
          <div
            v-for="(ssr, ssrIndex) in ssrData"
            :key="ssrIndex"
          >
            <BFormCheckbox v-model="ssr.isCheck">
              <div class="font-weight-bolder font-medium-1">
                {{ ssrIndex + 1 }} {{ ssr.ssrType }} {{ ssr.airline }} {{ ssr.status }} {{ ssr.ssrText }}
              </div>
            </BFormCheckbox>
          </div>
        </div>
        <BAlert
          v-if="errorMessage"
          show
          variant="danger"
          class="px-2 py-50"
        >
          {{ errorMessage }}
        </BAlert>
      </IAmOverlay>

      <template #modal-footer="{ cancel }">
        <div class="d-flex-center gap-2">
          <BButton
            variant="flat-dark"
            @click="cancel()"
          >
            {{ $t('close') }}
          </BButton>

          <BButton
            :variant="addOperation ? 'info' : 'danger'"
            :disabled="loading || (addOperation ? isDisabledAdd : isDisabledRemove)"
            @click="handleSubmit"
          >
            {{ addOperation ? $t('reservation.editCtcmCtceFor1g.addTitle') : $t('reservation.editCtcmCtceFor1g.removeTitle') }}
          </BButton>
        </div>
      </template>
    </BModal>
  </div>
</template>
<script>
import {
  BModal, BFormCheckbox, BFormInput, BInputGroup, BInputGroupPrepend, BButton, BFormRadio, BFormGroup, BAlert,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BFormRadio,
    BFormGroup,
    BAlert,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    bookingCode: { type: String, default: '' },
    source: { type: String, default: '' },
    agencyCode: { type: String, default: '' },
    paxCode: { type: String, default: '' },
    paxLists: { type: Array, default: () => [] },
    ssrLists: { type: Array, default: () => [] },
  },
  setup(props) {
    const { loading, bookSpecialServices } = useReservationHandle()
    const paxData = ref([])
    const ssrData = ref([])
    const typeOperation = ref('ADD') // ADD or DELETE
    const addOperation = computed(() => typeOperation.value === 'ADD')
    const errorMessage = ref('')
    function showHandle() {
      errorMessage.value = ''
      paxData.value = props.paxLists.map(pax => ({
        ...pax,
        isCheck: false,
        isEmailCheck: false,
        isPhoneCheck: false,
      }))
      ssrData.value = props.ssrLists.map(ssr => ({
        ...ssr,
        isCheck: false,
      }))
    }

    function modifyPayloadItem(pax, text, ssrType, operationType) {
      if (!text) {
        errorMessage.value = 'Vui lòng nhập số điện thoại/email'
        return null
      }
      errorMessage.value = ''
      return {
        specialServiceRequest: {
          text,
          ssrType,
          itineraries: [],
          paxInfo: {
            firstName: pax.firstName,
            lastName: pax.lastName,
            title: pax.title,
            paxType: pax.paxType,
            birthday: pax.birthday || '',
            paxId: pax.paxId,
            parentPaxId: pax.parentPaxId,
            familyId: '',
            pricingInfo: pax.pricingInfo,
            travelFlyer: pax.travelFlyer,
            document: pax.document,
            fareBasisCode: pax.fareBasisCode,
          },
        },
        operation: operationType,
        id: '',
      }
    }

    async function handleSubmit() {
      errorMessage.value = ''
      const specialServiceRequestUpdates = []
      if (addOperation.value) {
        paxData.value.forEach(pax => {
          if (pax.isCheck) {
            if (pax.isEmailCheck) {
              const itemToPush = modifyPayloadItem(pax, pax.email, 'CTCE', 'ADD')
              specialServiceRequestUpdates.push(itemToPush)
            }
            if (pax.isPhoneCheck) {
              const itemToPush = modifyPayloadItem(pax, pax.phone, 'CTCM', 'ADD')
              specialServiceRequestUpdates.push(itemToPush)
            }
          }
        })
      } else {
        ssrData.value.forEach(ssr => {
          if (ssr.isCheck) {
            const itemToPush = {
              operation: 'DELETE',
              id: ssr.id,
            }
            specialServiceRequestUpdates.push(itemToPush)
          }
        })
      }

      if (errorMessage.value) { return }
      const payload = {
        source: props.source,
        pnrNumber: props.bookingCode,
        agencyCode: props.agencyCode,
        paxCode: props.paxCode,
        specialServiceRequestUpdates,
      }

      await bookSpecialServices(payload, 'editSSR')
      this.$bvModal.hide('modal-edit-ctcm-ctce-for-1g-modal')
    }

    const isDisabledRemove = computed(() => !ssrData.value.some(ssr => ssr.isCheck))
    const isDisabledAdd = computed(() => !paxData.value.some(pax => pax.isCheck && (pax.isEmailCheck || pax.isPhoneCheck)))

    return {
      showHandle,
      paxData,
      handleSubmit,
      loading,
      isEmpty,
      typeOperation,
      addOperation,
      ssrData,
      isDisabledAdd,
      isDisabledRemove,
      errorMessage,
    }
  },
}
</script>
<style lang="scss" scoped>
.custom-radio::v-deep {
  .custom-control-label {

    &::before,
    &::after {
      width: 20px;
      height: 20px;
      top: 5px;
    }
  }
}
</style>
